<template>
  <div>
    <!-- skeleton -->
    <div class="photos-skeleton" v-if="isLoading">
      <div class="skeleton-line line-alpha"></div>
      <div class="skeleton-line line-beta"></div>
      <div class="skeleton-line line-epsilon"></div>
    </div>
    <div class="result-photos" v-else>
      <div class="photos-header">
        <div>
          <template v-if="!isFacialRecognition">
            <span v-if="hasPhotos">{{
              $t("result_photos_title", { bib_number: bib })
            }}</span>
            <span v-if="hasPhotos && !highlights" class="photos-count"
              >({{ photos.length }})</span
            >
            <span v-if="!hasPhotos">{{ $t("Photos") }}</span>
          </template>
          <template v-if="isFacialRecognition">
            <span>{{ componentTitle }}</span>
          </template>
        </div>
        <!-- Facial recognition buttons -->
        <template v-if="isFacialRecognition && uploadCompleted && hasPhotos">
          <div class="header-buttons">
            <template v-if="purchaseStatus === 'no_order_found'">
              <mem-button
                class="component-btn button-buy-now"
                dark
                keep-slot
                :loading="linkIsLoading"
                @click="openShopLink"
              >
                <i18n
                  v-if="photosInfo.photoAddonDetails.price"
                  tag="span"
                  path="buy_now_with_price"
                >
                  <span>{{
                    photosInfo.photoAddonDetails.price
                      | currency(
                        photosInfo.photoAddonDetails.country,
                        photosInfo.photoAddonDetails.currency
                      )
                  }}</span>
                </i18n>
                <span v-else>{{ $t("buy_now") }}</span>
              </mem-button>
              <mem-button
                btn-type="tertiary-dark"
                class="
                  component-btn
                  dialog-btn
                  loader-btn
                  button-refresh-result
                "
                :loading="resultRefreshing"
                :loader-size="20"
                @click="refreshResult"
                >{{ $t("i_purchased") }}</mem-button
              >
              <!-- <mem-button
                btn-type="tertiary-dark"
                class="component-btn button-update-selfie"
                @click="showSelfieDialog = true"
              >
                {{ $t("update_selfie") }}
              </mem-button> -->
            </template>
            <template v-else>
              <mem-button
                btn-type="tertiary-dark"
                class="component-btn loader-btn button-refresh-result"
                :loading="resultRefreshing"
                :loader-size="20"
                @click="refreshResult"
                >{{ $t("refresh") }}</mem-button
              >
              <template v-if="purchaseStatus === 'pending_production'">
                <v-dialog v-model="tooltip" max-width="334">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="upload-status" v-bind="attrs" v-on="on">
                      <span class="material-icons activator-icon"> info </span>
                      <div>{{ $t("preparing_the_originals") }}</div>
                    </div>
                  </template>
                  <div class="modal-body">
                    <span>{{ $t("result_photos_tooltip") }}</span>
                  </div>
                </v-dialog>
              </template>
            </template>
          </div>
        </template>
      </div>

      <div class="photos-processing" v-if="!hasPhotos && !uploadCompleted">
        <img src="@/assets/hourglass_icon.svg" alt="time" />
        <span class="processing-title">{{ $t("photos_url_is_blank") }}</span>
      </div>

      <!-- Facial Recognition Result -->
      <template v-if="isFacialRecognition && uploadCompleted">
        <div class="starting-block" v-if="!hasPhotos && !hasUploadedSelfie">
          <div class="block-details">
            <div class="block-title">{{ $t("get_your_race_photos") }}</div>
            <div class="block-subline">{{ $t("upload_selfie_hint") }}</div>
            <div class="decision-buttons">
              <mem-button
                class="component-btn upload-button button-upload-selfie"
                btn-type="secondary-dark"
                @click="showSelfieDialog = true"
                >{{ $t("upload_photo") }}</mem-button
              >
            </div>
          </div>
          <img src="@/assets/selfie_demo.svg" alt="selfie" />
        </div>

        <div class="photos-not-found" v-if="!hasPhotos && hasUploadedSelfie">
          <span class="material-icons"> person_search </span>
          <span class="msg-text">{{ $t("upload_selfie_no_results") }}</span>
          <div class="decision-buttons">
            <mem-button
              class="component-btn upload-button button-update-selfie"
              btn-type="secondary-dark"
              @click="showSelfieDialog = true"
              >{{ $t("update_selfie") }}</mem-button
            >
          </div>
        </div>

        <div class="additional-upload-hint" v-if="hasPhotos">
          <span>{{ $t("here_are_your_photos") }}</span>
        </div>
      </template>

      <!-- Photos -->
      <!-- desktop version -->
      <div class="photos-preview" v-if="hasPhotos">
        <v-carousel
          v-model="activePhoto"
          height="702"
          hide-delimiters
          show-arrows-on-hover
          vertical
          :show-arrows="false"
        >
          <template v-for="(photo, key) in photos">
            <v-carousel-item :key="key" :value="photo" class="carousel-item">
              <v-img :src="preloadImage(photo)" class="photo-blur" />
              <v-img :src="preloadImage(photo)" class="photo-original" />
              <div class="share-buttons">
                <!-- share photo -->
                <template v-if="showShareOptions">
                  <div
                    class="share-button facebook-btn"
                    @click="shareClick('facebook', photo)"
                  >
                    <img src="@/assets/logo_facebook.svg" alt="fb" />
                  </div>
                  <div
                    class="share-button twitter-btn"
                    @click="shareClick('twitter', photo)"
                  >
                    <img src="@/assets/logo_twitter.svg" alt="tw" />
                  </div>
                </template>
                <!-- download photo -->
                <div
                  class="download-btn button-download"
                  v-if="canDownload"
                  @click="downloadPhoto(photo)"
                >
                  <i class="material-icons">get_app</i>
                </div>
              </div>
            </v-carousel-item>
          </template>
        </v-carousel>
        <div class="photos-list" id="photos-list">
          <div class="prev-arrow" v-if="activePhotoKey !== 0" @click="prev">
            <img src="@/assets/arrow.svg" alt="prev" />
          </div>
          <div
            class="next-arrow"
            v-if="activePhotoKey !== photos.length - 1"
            @click="next"
          >
            <img src="@/assets/arrow.svg" alt="next" />
          </div>
          <div
            class="photo-item"
            :class="{ 'is-active': key === activePhotoKey }"
            v-for="(photo, key) in photos"
            :key="key"
            :id="`photo-item-${key}`"
            @click="setActivePhoto(photo, key)"
          >
            <v-img
              :src="photo.thumbnail || photo.thumb"
              height="133"
              width="133"
            />
            <div class="item-border"></div>
          </div>
        </div>
      </div>
      <!-- mobile version (old code)-->
      <div class="mobile-photos-list" v-if="false">
        <div class="photo-items-wrap">
          <div class="photo-item" v-for="(photo, key) in photos" :key="key">
            <v-img :src="preloadImage(photo)" class="photo-blur"></v-img>
            <v-img :src="preloadImage(photo)" class="photo-original" />
            <div class="share-buttons">
              <!-- share photo -->
              <template v-if="showShareOptions">
                <div
                  class="share-button facebook-btn"
                  @click="shareClick('facebook', photo)"
                >
                  <img src="@/assets/logo_facebook.svg" alt="fb" />
                </div>
                <div
                  class="share-button twitter-btn"
                  @click="shareClick('twitter', photo)"
                >
                  <img src="@/assets/logo_twitter.svg" alt="tw" />
                </div>
              </template>
              <!-- download photo -->
              <div
                v-if="canDownload"
                class="download-btn"
                @click="downloadPhoto(photo)"
              >
                <i class="material-icons">get_app</i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile version (new code)-->
      <div v-if="hasPhotos" class="mobile-photos-list">
        <photos-mobile-list :photos="photos" :canDownload="canDownload" />
      </div>

      <div
        v-if="isFacialRecognition && uploadCompleted && hasPhotos"
        class="advanced-search"
      >
        <img
          class="glasses-icon"
          src="@/assets/opera_glasses.svg"
          alt="glasses"
        />
        <div class="el-description">
          {{ $t("result_photos_advanced_search_text") }}
        </div>
        <mem-button btn-type="secondary-dark" @click="openSearch">{{
          $t("search")
        }}</mem-button>
      </div>

      <!-- <div
      class="subscription-section"
      v-if="showSpartanPlus && !canDownload && hasPhotos"
    >
      <div class="subscription-info">
        <div class="subscription-title">
          <i class="material-icons">lock</i>
          <span>{{ $t("join") }} spartan+</span>
        </div>
        <div>{{ $t("unlock_photos_download_text") }}</div>
      </div>
      <mem-button class="component-btn" @click="openSubscriptionPage">{{
        $t("join_now")
      }}</mem-button>
    </div> -->

      <v-dialog v-model="showShareDialog" :max-width="582" class="mem-dialog">
        <share-dialog
          v-if="showShareDialog"
          :options="dialogOptions"
          :onCancel="closeDialog"
        />
      </v-dialog>

      <v-dialog
        v-model="showSelfieDialog"
        :max-width="480"
        :fullscreen="isFullscreen"
        class="mem-dialog"
      >
        <selfie-dialog
          v-if="showSelfieDialog"
          :result-id="photosInfo.result_id"
          :is-fullscreen="isFullscreen"
          :is-mobile="isMobile"
          :onClose="closeDialog"
        ></selfie-dialog>
      </v-dialog>

      <error-dialog
        v-model="errorDialog"
        :error-text="errorText"
        :is-fullscreen="isFullscreen"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "share-dialog": () => import("./ShareDialog.vue"),
    "selfie-dialog": () => import("./ResultSelfieUploadDialog.vue"),
    "error-dialog": () => import("./DialogServiceError.vue"),
    "photos-mobile-list": () => import("./PhotosMobileList.vue"),
  },
  data: () => ({
    activePhoto: {},
    activePhotoKey: 0,
    showShareDialog: false,
    showSelfieDialog: false,
    errorDialog: false,
    errorText: "",
    dialogOptions: {},
    selectedPhoto: new Image(),
    showShareOptions: false,
    env: process.env.VUE_APP_ENV,
    tooltip: false,
    resultRefreshing: false,
    isLoading: true,
    linkIsLoading: false,
  }),
  props: {
    userPhotos: {
      type: Array,
      default: () => {
        return [];
      },
    },
    bonusPhotos: {
      type: Array,
      default: () => {
        return [];
      },
    },
    highlights: Boolean,
    bib: [Number, String],
    eventId: [Number, String],
    canDownload: Boolean,
    country: String,
    photosInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {},
  computed: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    photos() {
      return [...this.userPhotos, ...this.bonusPhotos];
    },
    hasPhotos() {
      return this.userPhotos.length !== 0;
    },
    componentTitle() {
      if (this.userPhotos.length !== 0 && this.bonusPhotos.length !== 0)
        return `${this.$t("result_photos_title_racer", {
          count: this.userPhotos.length,
        })} + ${this.$t("result_photos_title_event_photos", {
          count: this.bonusPhotos.length,
        })}`;

      if (this.hasPhotos)
        return this.$t("result_photos_title_racer_single", {
          count: this.photos.length,
        });

      return this.$t("Photos");
    },
    // showSpartanPlus() {
    //   let supportedCountries = ["US"];
    //   return supportedCountries.includes(this.country);
    // },
    isFacialRecognition() {
      return this.photosInfo.photo_provider === "sportograf";
    },
    uploadCompleted() {
      return this.photosInfo.sportograf_status === "online";
    },
    hasUploadedSelfie() {
      return this.photosInfo.sportograf_selfie_uploaded;
    },
    purchaseStatus() {
      // return "no_order_found";
      // return "pending_production";
      return this.photosInfo.sportograf_purchase_status;
    },
    isFullscreen() {
      // TODO: Find a way to avoid vuetify variable use
      return this.$vuetify.breakpoint.width <= 499;
    },
  },
  methods: {
    ...mapActions([
      "uploadSelfie",
      "getResult",
      "getResultPhotos",
      "getPhotosCartUrl",
    ]),
    async openShopLink() {
      if (this.linkIsLoading) return;
      this.linkIsLoading = true;
      let newWindow = window.open("about:blank", "_blank");
      try {
        let link = await this.getPhotosCartUrl(this.photosInfo.result_id);
        if (link && newWindow) {
          newWindow.location.href = link;
        } else {
          newWindow?.close();
        }
        this.linkIsLoading = false;
      } catch (error) {
        console.log("Something went wrong", error);
        this.linkIsLoading = false;
      }
    },
    openSearch() {
      let windowReference = window.open();
      windowReference.location = this.photosInfo.sportograf_login_link;
    },
    // openSubscriptionPage() {
    //   this.$router.push({ name: "subscription" });
    // },
    preloadImage(photo) {
      if (!photo) return "";
      // socialmedia
      if (photo.socialmedia) return photo.socialmedia;
      // preview
      if (photo.preview) return photo.preview;

      return `${photo.watermarked_preview}?x-test-img=membership`;
    },
    setActivePhoto(photo, key) {
      this.activePhoto = photo;
      this.activePhotoKey = key;
    },
    prev() {
      if (this.activePhotoKey === 0) return;
      this.activePhotoKey = this.activePhotoKey - 1;
      this.activePhoto = this.photos[this.activePhotoKey];
      this.scrollList();
    },
    next() {
      if (!this.photos || this.activePhotoKey === this.photos.length) return;
      this.activePhotoKey = this.activePhotoKey + 1;
      this.activePhoto = this.photos[this.activePhotoKey];
      this.scrollList();
    },
    scrollList() {
      this.$nextTick(() => {
        let myElement = document.getElementById(
          `photo-item-${this.activePhotoKey}`
        );
        let topPos = myElement.offsetTop;
        document.getElementById("photos-list").scrollTop = topPos - 34;
      });
    },
    shareClick(type, photoObj) {
      let imageURL = photoObj.watermarked_preview;
      // let downloadedImg = new Image();
      this.selectedPhoto.crossOrigin = "Anonymous";
      this.selectedPhoto.addEventListener("load", this.imageReceived, false);
      this.selectedPhoto.src = imageURL;
      // this.showShareDialog = true;
      this.dialogOptions = {
        type: type,
        resultId: this.$route.params.id,
        photo: photoObj,
      };
    },
    imageReceived() {
      console.log("image loaded");
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");

      canvas.width = this.selectedPhoto.width;
      canvas.height = this.selectedPhoto.height;

      context.drawImage(this.selectedPhoto, 0, 0);
      // imageBox.appendChild(canvas);

      try {
        localStorage.setItem("photoPreviewUrl", canvas.toDataURL("image/png"));
        this.showShareDialog = true;
      } catch (err) {
        console.log("Error: " + err);
      }
    },
    async downloadPhoto(photo) {
      if (!photo) return;
      let downloadLink =
        photo.original || photo.socialmedia || photo.watermarked_preview;

      try {
        // let res = await fetch(downloadLink);
        // let blob = await res.blob();
        let a = document.createElement("a");
        // a.href = URL.createObjectURL(blob);

        a.href = downloadLink;
        a.setAttribute("download", `${photo.athlete_image_id}.jpg`);
        a.click();
      } catch (error) {
        console.log("get photo error", error);
      }
    },
    closeDialog() {
      this.showShareDialog = false;
      this.showSelfieDialog = false;
    },
    async refreshResult() {
      this.resultRefreshing = true;
      const fd = new FormData();
      fd.append("force", "1");
      try {
        let uploadResult = await this.uploadSelfie({
          image: fd,
          resultId: this.photosInfo.result_id,
        });
        this.resultRefreshing = false;

        // Show Sportograf service errors
        if (uploadResult.not_found_reason === "bib_missmatch") {
          this.errorText = "result_error_bib_missmatch";
          this.errorDialog = true;
        }
        if (uploadResult.not_found_reason === "email_missmatch") {
          this.errorText = "result_error_email_missmatch";
          this.errorDialog = true;
        }
      } catch (error) {
        this.resultRefreshing = false;
        console.log("Refresh result error", error);
      }
    },
  },
  async created() {
    if (!this.hasUploadedSelfie) {
      this.isLoading = false;
      return;
    }
    try {
      await this.getResultPhotos(this.photosInfo.result_id);
      this.isLoading = false;
    } catch (error) {
      console.log("Photos not loaded", error);
    }
  },
};
</script>
<style lang="scss" scoped>
.modal-body {
  background-color: #fff;
  padding: 24px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.photos-skeleton {
  background-color: #121212;
  min-height: 245px;
  margin-top: 58px;
  margin-bottom: 60px;
  padding-top: 40px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $mobile) {
    min-height: 320px;
    margin-bottom: 50px;
    padding-top: 0;
    padding-bottom: 40px;
    padding-left: 0;
    align-items: center;
    justify-content: flex-end;
  }
  .skeleton-line {
    position: relative;
    overflow: hidden;
    background-color: #121212;
    &::after {
      @include animatedLine;
    }
  }
  .line-alpha {
    height: 20px;
    width: 210px;
  }
  .line-beta {
    height: 20px;
    width: 330px;
    margin-top: 12px;
    margin-bottom: 20px;
    @media screen and (max-width: $mobile) {
      margin-top: 8px;
      margin-bottom: 40px;
    }
  }
  .line-epsilon {
    height: 34px;
    width: 140px;
  }
}
.result-photos {
  margin-bottom: 60px;
  @media screen and (max-width: 499px) {
    margin-bottom: 50px;
  }
  .component-btn {
    height: 37px;
    padding: 0 16px;

    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    letter-spacing: 0.04em;
  }
  .photo-blur {
    height: 100%;
    background-size: cover;
    background-position: 50%;
    filter: blur(15px);
  }
  .photo-original {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .share-buttons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    padding-top: 28px;
    padding-right: 28px;
    @media screen and (max-width: 499px) {
      padding-top: 20px;
      padding-right: 18px;
    }
    .share-button {
      height: 37px;
      width: 37px;
      // margin-right: 12px;
      border-radius: 19px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include cursorPointer;
    }
    .twitter-btn {
      background: #3fa3ff;
    }
    .twitter-btn:hover {
      background: #4781c6;
      transition: all 200ms ease-in-out;
    }
    .facebook-btn {
      background: #3473d0;
      margin-right: 12px;
    }
    .facebook-btn:hover {
      background: #355ba1;
      transition: all 200ms ease-in-out;
    }
    .download-btn,
    .download-disabled {
      height: 37px;
      width: 37px;
      border-radius: 19px;
      margin-left: 12px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      // position: relative;
      @include cursorPointer;
    }
    .download-btn {
      color: #000000;
      background-color: rgba(255, 255, 255, 1);
      transition: all 200ms ease-out;
    }
    .download-btn:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    .download-disabled {
      color: #aaaaaa;
      background-color: #efefef;
      i {
        font-size: 20px;
      }
    }
  }
  .hide-element {
    @media screen and (max-width: 499px) {
      display: none;
    }
  }
  .decision-buttons {
    @media screen and (max-width: 499px) {
      display: grid;
      grid-template-columns: max-content max-content;
    }
    .photos-button {
      display: none;
      @media screen and (max-width: 499px) {
        margin-left: 10px;
        display: block;
      }
    }
  }
  .photos-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    font-size: 24px;
    line-height: 28px;
    font-weight: bold;

    @media screen and (max-width: 499px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 20px;
      padding-right: 20px;
      .component-btn {
        margin-top: 20px;
      }
    }
    .header-buttons {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      @media screen and (max-width: $mobile) {
        flex-direction: column;
        width: 100%;
      }
      .upload-status {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-size: 12px;
        font-weight: 600;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.24px;
        text-transform: uppercase;
        margin-left: 14px;
        @media screen and (max-width: 499px) {
          margin-top: 25px;
          margin-left: 0;
          justify-content: center;
        }
        .activator-icon {
          color: #605e5e;
          font-size: 15px;
          margin-right: 6px;
        }
      }
      .component-btn {
        @media screen and (max-width: 499px) {
          // margin-top: 25px;
          margin-top: 12px;
        }
      }
      .dialog-btn {
        margin-left: 10px;
        @media screen and (max-width: 499px) {
          // margin-right: 14px;
          margin-left: 0;
        }
      }
      .loader-btn {
        min-width: 126px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      // .button-buy-now {
      // }
      // .button-refresh-result {
      // }
      .button-update-selfie {
        margin-left: 10px;
        @media screen and (max-width: $mobile) {
          margin-left: 0;
        }
      }
    }

    .photos-count {
      color: #808080;
      margin-left: 6px;
      letter-spacing: 0.04em;
    }
  }
  .no-matches-hint {
    display: flex;
    flex-direction: column;
    padding-top: 22px;
    padding-bottom: 15px;
    @media screen and (max-width: 499px) {
      padding-top: 23px;
      padding-left: 16px;
      padding-right: 16px;
    }
    .hint-text {
      display: flex;
      flex-direction: row;
      max-width: 470px;
      font-size: 14px;
      line-height: 18px;
      .v-image {
        margin-right: 16px;
        margin-top: 1px;
      }
    }
    .hint-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-top: 16px;
      .component-btn {
        padding: 0 15px;
      }
      @media screen and (max-width: 499px) {
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 23px;
      }
      .component-btn:first-child {
        margin-right: 16px;
        @media screen and (max-width: 499px) {
          margin-right: 0;
          margin-bottom: 8px;
        }
      }
    }
  }
  .photos-processing {
    height: 247px;
    background-image: url("../../../assets/result_photos_none_bg.png");
    background-position-y: 50%;
    background-size: cover;
    // background-repeat: no-repeat;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 30px;

    @media screen and (max-width: 499px) {
      height: 335px;
      background-position-x: 50%;
      background-size: auto;
      padding: 0 56px;
      margin-top: 60px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 35px;
    }

    .processing-title {
      max-width: 356px;
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      margin-top: 30px;
      @media screen and (max-width: 499px) {
        margin-top: 24px;
      }
    }
  }
  // facial recognition blocks
  .starting-block {
    min-height: 245px;
    background-color: #121212;
    margin-top: 30px;
    padding: 40px 50px 40px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 499px) {
      padding: 30px 0 40px;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: center;
    }
    .block-details {
      @media screen and (max-width: 499px) {
        max-width: 262px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .block-title {
        font-size: 18px;
        line-height: 27px;
        font-weight: bold;
        text-transform: capitalize;
        margin-bottom: 8px;
        @media screen and (max-width: 499px) {
          line-height: 22px;
        }
      }
      .block-subline {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
        @media screen and (max-width: 499px) {
          margin-bottom: 40px;
          text-align: center;
        }
      }
      // .block-buttons {

      // }
    }
    img {
      @media screen and (max-width: 499px) {
        height: 120px;
        width: 120px;
        margin-bottom: 30px;
      }
    }
  }
  .photos-not-found {
    min-height: 245px;
    margin-top: 30px;
    background-color: #121212;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 45px;
    @media screen and (max-width: 499px) {
      min-height: 370px;
      margin-top: 20px;
      padding-bottom: 62px;
    }
    .material-icons {
      font-size: 46px;
      color: #414141;
      @media screen and (max-width: 499px) {
        font-size: 68px;
      }
    }
    .msg-text {
      font-size: 16px;
      line-height: 22px;
      margin-top: 6px;
      margin-bottom: 16px;
      text-align: center;
      max-width: 434px;
      @media screen and (max-width: 499px) {
        font-size: 14px;
        line-height: 21px;
        margin-top: 26px;
        margin-bottom: 36px;
        max-width: 246px;
      }
    }
  }
  .additional-upload-hint {
    margin-top: 20px;
    font-size: 16px;
    line-height: 22px;
    max-width: 490px;
    @media screen and (max-width: 499px) {
      margin-top: 25px;
      padding-right: 20px;
      padding-left: 20px;
    }
    @media screen and (max-width: 375px) {
      max-width: 312px;
      padding-right: 0;
    }
  }
  //
  .photos-preview {
    background-color: #121212;
    padding: 34px 24px 44px;
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr 133px;
    column-gap: 22px;
    position: relative;
    @media screen and (max-width: 499px) {
      display: none;
    }
    .carousel-item {
      position: relative;
    }
    .next-arrow,
    .prev-arrow {
      position: absolute;
      right: 72px;
      color: #000000;
      height: 37px;
      width: 37px;
      border-radius: 100%;
      background-color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      z-index: 10;
      @include cursorPointer;
      .material-icons {
        font-size: 32px;
      }
    }
    .next-arrow {
      bottom: 24px;
    }
    .prev-arrow {
      top: 16px;
      transform: rotate(180deg);
    }
    .photos-list {
      height: 702px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      scroll-behavior: smooth;
      .photo-item {
        margin-bottom: 22px;
        z-index: 5;
        opacity: 0.6;
        .v-image {
          border-radius: 4px;
        }
      }
      .is-active {
        opacity: 1;
      }
      .photo-item:last-child {
        margin-bottom: 0;
      }
      .photo-item::after {
        display: none;
      }
      .is-active,
      .photo-item:hover {
        position: relative;
      }
      .is-active::after,
      .photo-item:hover::after {
        display: block;
        content: " ";
        border: 2px solid #ffffff;
        position: absolute;
        height: 133px;
        width: 133px;
        top: 0;
        border-radius: 4px;
      }
    }
    .photos-list::-webkit-scrollbar {
      display: none;
    }
  }
  .mobile-photos-list {
    margin-top: 30px;
    max-width: 100vw;
    display: none;
    @media screen and (max-width: 499px) {
      display: block;
    }
    .photo-items-wrap {
      height: 498px;
      display: flex;
      flex-direction: row;
      // overflow: hidden;
      overflow-x: auto;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 34px;
      margin-bottom: 30px;
      /* Firefox */
      scrollbar-width: thin;
      scrollbar-color: #ffffff #1e1e1e;
      .photo-item {
        position: relative;
        min-width: 308px;
        overflow: hidden;
        margin-right: 20px;
        border-radius: 4px;
        .photo-blur {
          opacity: 0.666;
        }
      }
      .photo-item:last-child {
        margin-right: 0;
      }
    }
    .photo-items-wrap::-webkit-scrollbar {
      height: 3px;
    }
    .photo-items-wrap::-webkit-scrollbar-track {
      background: #353333;
      background-clip: padding-box;
      border: 20px solid rgba(0, 0, 0, 0);
    }
    .photo-items-wrap::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      background-clip: padding-box;
      border: 20px solid rgba(0, 0, 0, 0);
    }
  }
  .advanced-search {
    background-color: #323232;
    padding: 32px 26px;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
    }
    .glasses-icon {
      height: 24px;
      width: 24px;
      margin-right: 16px;
      @media screen and (max-width: $mobile) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
    .el-description {
      width: 100%;
      padding-right: 50px;
      font-size: 14px;
      font-weight: 500;
      line-height: 130%;
      @media screen and (max-width: $mobile) {
        padding-right: 0;
        margin-bottom: 20px;
      }
    }
    button {
      height: 37px;
      padding: 0 16px;
      color: #020000;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.48px;
      text-transform: uppercase;
    }
  }
  .subscription-section {
    height: 102px;
    background-color: #1e1e1e;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    font-size: 16px;
    line-height: 22px;

    @media screen and (max-width: 499px) {
      height: 180px;
      flex-direction: column;
      padding: 0 20px;
      align-items: flex-start;
      justify-content: center;
    }
    .subscription-info {
      @media screen and (max-width: 499px) {
        margin-bottom: 18px;
        max-width: 280px;
      }
      .subscription-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        line-height: 15px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        margin-bottom: 18px;
        i {
          font-size: 15px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>